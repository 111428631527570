define(["mojo/context", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/23f13825788d3d62d0a39e33db298dc8c65df775/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/23f13825788d3d62d0a39e33db298dc8c65df775/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/hash", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/23f13825788d3d62d0a39e33db298dc8c65df775/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (context, domConstruct, array, hash, ioQuery) {
  return {
    /**
     * Build a URL using the project's base url and handle common mistakes we do by having additional slashes.
     * Also allows you tag additional parameters
     *
     * @param {!string} url - Can be with or without a starting slash
     * @param {object=} urlParams an object with url parameters
     *
     * @returns {string} new formed url
     */
    toUrl: function (url, urlParams) {
      if (typeof url == 'undefined' || url == null) {
        url = '';
      }

      // Strip starting slash if it's present.
      if (url && url[0] == '/') {
        url = url.substr(1);
      }
      if (urlParams) {
        url += url.indexOf("?") < 0 ? "?" : "&";
        url += ioQuery.objectToQuery(urlParams);
      }
      return context.rootUrl + url;
    },
    /**
     * Test whether string is in the current URL.
     *
     * @param {!string} url - Can be with or without a starting slash
     *
     * @returns {bool}
     */
    inUrl: function (url) {
      return window.location.href.indexOf(url) > -1;
    },
    /**
     * Unwrap a url that has been proxied.
     *
     * @param {!string} url
     *
     * @returns {string} modified url without the wrapped proxy
     */
    removeProxy: function (url) {
      if (url.indexOf('_ssl/proxy.php') > 0) {
        // Don't assume the proxied url is always http
        var protocol = url.indexOf("_ssl/proxy.php/https/") >= 0 ? "https" : "http";
        var numToSkip = ('_ssl/proxy.php/' + protocol + '/').length;
        url = protocol + '://' + url.substring(url.indexOf('_ssl/proxy.php/') + numToSkip);
      }
      return url;
    },
    /**
     * Given a url, it will wrap it with the proxy if it's not already https, unless it's a cdn-images or gallery URL (they now support HTTPS)
     *
     * @param {!string} url
     * @returns {string} a url that will not cause SSL browser warnings.
     */
    addProxy: function (url) {
      var link = domConstruct.create('a', {
        'href': url
      });
      var protocol = link.protocol.replace(":", "");
      if (protocol == "https") {
        return url;
      } else {
        if (context.avestaEnvironment == 'prod' && link.hostname == context.galleryDomain || link.hostname == context.cdnImagesDomain) {
          return url.replace(/^http:/i, 'https:');
        }
        var parsed = protocol + "/" + link.hostname;
        if (link.pathname[0] != "/") parsed += "/";
        parsed += link.pathname;
        if (link.search) {
          parsed += "?" + encodeURIComponent(link.search);
        }
        return context.proxyBaseUrl + "/" + parsed;
      }
    },
    /**
     * Return the hash params for the page
     *
     * @returns {object}
     */
    getHashParams: function () {
      var currentHash = hash();
      var params = {};
      if (currentHash) {
        var hashVals = currentHash.split(';');
        if (hashVals && hashVals.length) {
          array.forEach(hashVals, function (val) {
            var setting = val.split(":");
            if (setting[0]) {
              params[setting[0]] = setting[1];
            }
          });
        }
      }
      return params;
    },
    /**
     * Set the hash params for the page
     *
     * @param {!object} params
     */
    setHashParams: function (params) {
      var hashVals = [];
      for (var key in params) {
        hashVals.push(key + ":" + params[key]);
      }
      hash(hashVals.join(";"), true);
    }
  };
});